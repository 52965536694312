import { useState } from 'react';
import {
isValidEmail,
        isValidPassword,
        isValidConfirmPassword,
        isValidTextFieldEntry,
        isValidTitle,
        isValidName,
        isValidTimeRange
        } from './../../../util/ValidationUtils';

//import { validateAccountForm } from '../utils/validationUtils'; 

const useForm = (initialValues) => {
    const [formData, setFormData] = useState(initialValues);
    const [hasChanges, setHasChanges] = useState(false);

    const [errors, setErrors] = useState({});

    const handleChange = (e) => {
        const {name, value, checked, type} = e.target;

        if (type === 'checkbox') {
            if (Array.isArray(formData[name])) {
                // Handle arrays (e.g., multiple checkboxes)
                let values = formData[name] || [];
                if (checked) {
                    values = [...values, value];
                } else {
                    values = values.filter((v) => v !== value);
                }
                setFormData((prevFormData) => ({
                        ...prevFormData,
                        [name]: values,
                    }));
            } else {
                // Handle single checkbox (boolean)
                setFormData((prevFormData) => ({
                        ...prevFormData,
                        [name]: checked,
                    }));
            }
        } else {
            setFormData((prevFormData) => ({
                    ...prevFormData,
                    [name]: value,
                }));
        }

        if (errors[name]) {
            setErrors((prevErrors) => ({
                    ...prevErrors,
                    [name]: [],
                }));
        }

        setHasChanges(true);
        validateField(name, value);
    };

    const validateField = (name, value) => {
        let fieldErrors = [];

        switch (name) {
            case 'email':
                isValidEmail(value, fieldErrors);
                break;
            case 'password':
                isValidPassword(value, fieldErrors);
                break;
            case 'confirmPassword':
                isValidConfirmPassword(formData.password, value, fieldErrors);
                break;
            case 'title':
                isValidTitle(value, fieldErrors);
                break;
            case 'startTime':
                isValidTimeRange(value, formData.endTime, fieldErrors);
                break;
            case 'endTime':
                isValidTimeRange(formData.startTime, value, fieldErrors);
                break;
            case 'bio':
            case 'contactInfo':
            case 'description':
            case 'researchInterests':
                isValidTextFieldEntry(value, fieldErrors);
                break;
            default:
                break;
        }

        setErrors((prevErrors) => ({...prevErrors, [name]: fieldErrors}));
    };
    /*
     const validate = () => {
     const { isValid, errors: validationErrors } = validateAccountForm(formData);
     setErrors(validationErrors);
     return isValid;
     };
     */
    return {formData, setFormData, handleChange, errors, setErrors, setHasChanges, hasChanges};
};

export default useForm;
