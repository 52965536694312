import React from 'react';
import SocialMediaIcons from './../socialmediaicons/SocialMediaIcons';
import './Footer.css';




const Footer = () => {

    return (<div className='footer' >
        <div className="footer-social">
            <SocialMediaIcons />
            </div>
        <div className="footer-bottom">
            <p>&copy; 2024 Sean Hadley Mathematics. All rights reserved.</p>
        </div>
    
    
    </div>);
};


export default  Footer;