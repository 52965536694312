import React, { useState, useEffect } from 'react';
import useUserAPI from './hooks/useUserAPI';
import Loading from './../loading/Loading';
import { validImageTypes, MAX_SIZE } from './../../util/ValidationUtils';
import useForm from './hooks/useForm';
import './AccountManager.css';


const AccountManager = () => {
    const {getPresignedURL, uploadToS3, user, updateUserDetails} = useUserAPI();
    const [image, setImage] = useState(null);
    const [preview, setPreview] = useState(null);
    const [error, setError] = useState('');
    const [editMode, setEditMode] = useState(false);

    const initialValues = {
    email: '',
    bio: '',
    contactInfo: '',
    researchInterests: '',
    profilePicture: '',
    phone: '',
    office: '',
    password: '',
    confirmPassword: ''
  };

  const { formData, setFormData, handleChange, errors, setErrors,setHasChanges, hasChanges, handleBlur , touched} = useForm(initialValues);

  useEffect(() => {
    if (user) {
      setFormData({
        email: user.email || '',
        bio: user.bio || '',
        profilePicture: user.profilePicture || '',
        contactInfo: user.contactInfo || '',
        researchInterests: user.researchInterests || '',
        phone: user.phone || '',
        office: user.office || '',
        password: '',
        confirmPassword: '',
      });
    }
  }, [user, setFormData]);
    
    

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        setError('');
        setPreview(null);

        if (file) {
            if (file.size > MAX_SIZE) {
                setError('File size must be under 25MB');
                return;
            }
            if (!validImageTypes.includes(file.type)) {
                setError('Only image files (JPEG, PNG, GIF, WEBP) are allowed');
                return;
            }

            setImage(file);
            setPreview(URL.createObjectURL(file));
            setHasChanges(true);
        }
    };


    const toggleEditMode = () => {
        setEditMode(!editMode);
    };

    const handleSaveChanges = async () => {
        if (formData.password && formData.password !== formData.confirmPassword) {
            setError('Passwords do not match.');
            return;

        }

        if (image) {
            try {
                const presignedURL = await getPresignedURL();
                await uploadToS3(image, presignedURL[0]);
                const newProfilePictureURL = presignedURL[1];
                setFormData((prevState) => ({
                        ...prevState,
                        profilePicture: newProfilePictureURL
                    }));
            } catch (error) {
                setError('Error uploading image: ' + error.message);
                return;
            }
        }

        console.log(formData);
        await updateUserDetails(formData);
        setHasChanges(false);
        setEditMode(false);
    };

    if (!user)
        return <Loading />;

    return (
            <div className="account-container">
                    <form className="form-content">
                    {editMode ? (
                                    <>
                                    <div className="upload-container">
                                        <input type="file" accept="image/*" onChange={handleImageChange} className="input-file" />
                                    </div>
                                      {touched.email && errors.email && errors.email.length > 0 && (
    <div className="error-message">{errors.email[0]}</div>
  )}
                                    <div className="form-group">
                                        <label htmlFor="email">Email:</label>
                                        <input type="text" name="email" id="email" value={formData.email} onChange={handleChange} onBlur={handleBlur} />
                                  </div>
                                    <div className="form-group">
                                        <label htmlFor="bio">Bio:</label>
                                        <textarea name="bio" id="bio" value={formData.bio} onChange={handleChange} rows="4" className="textarea-bio"></textarea>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="contactInfo">Contact Info:</label>
                                        <textarea name="contactInfo" id="contactInfo" value={formData.contactInfo} onChange={handleChange} rows="4" className="textarea-contactInfo"></textarea>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="researchInterests">Research Interests:</label>
                                        <textarea name="researchInterests" id="researchInterests" value={formData.researchInterests} onChange={handleChange} rows="4" className="textarea-researchInterests"></textarea>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="phone">Phone:</label>
                                        <input type="text" name="phone" id="phone" value={formData.phone} onChange={handleChange} />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="office">Office:</label>
                                        <input type="text" name="office" id="office" value={formData.office} onChange={handleChange} />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="password">New Password:</label>
                                        <input type="password" name="password" id="password" value={formData.password} onChange={handleChange} />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="confirmPassword">Confirm Password:</label>
                                        <input type="password" name="confirmPassword" id="confirmPassword" value={formData.confirmPassword} onChange={handleChange} />
                                    </div>
                                    </>
                                        ) : (
                            <>
                            <div className="preview-container">
                                {preview ? (
                                                        <div className="image-detail">
                                                            <div className="image-wrapper">
                                                                <img src={preview} alt="Preview" />
                                                            </div>
                                                        </div>
                                                            ) : (
                                                <img src={user.profilePicture} alt="Profile" />
                                                    )}
                            </div>
                            <div className="form-group">
                                <label>Email:</label>
                                <span>{formData.email}</span>
                            </div>
                            <div className="form-group">
                                <label>Bio:</label>
                                <div className="bio-content">{formData.bio}</div>
                            </div>
                            <div className="form-group">
                                <label>Contact Info:</label>
                                <div className="bio-content">{formData.contactInfo}</div>
                            </div>
                            <div className="form-group">
                                <label>Research Interests:</label>
                                <div className="bio-content">{formData.researchInterests}</div>
                            </div>
                            <div className="form-group">
                                <label>Phone:</label>
                                <span>{formData.phone}</span>
                            </div>
                            <div className="form-group">
                                <label>Office:</label>
                                <span>{formData.office}</span>
                            </div>
                            </>
                                )}
            
                    <button type="button" onClick={editMode ? handleSaveChanges : toggleEditMode} className="edit-save-button">
                        {editMode ? 'Save Changes' : 'Edit'}
                    </button>
                </form>
            </div>
            );
};

export default AccountManager;
