import React, {useState, useEffect} from 'react';
import axios from 'axios';
import './Contact.css';



const Contact = () => {
    const [courses, setCourses] = useState([]);
    const [user, setUser] = useState({});
    
        
    
    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/user`)
                .then(response => {
                  //  console.log('Response : ', response.data);
                    setCourses(response.data.userCourses);
                    setUser(response.data);
               //     console.log(response.data.userCourses);
                }).catch(error => {
            console.error("There was an error", error);
        });


    }, []);

    return (
            <div className="contact-content">
                <h1>Contact Sean </h1>                
                <p>{user.contactInfo}</p>
                <p>Sean also tutors San Francisco State Univeristy student at the The Tutoring and Academic Support Center (TASC) located in the library on the third floor. For help Scheduling an appointment with Sean Click <a href="https://tutoring.sfsu.edu/scheduling-tutoring-appointment">Here</a></p> 
                <p>You may also find Sean spending time in the Math Club room in <span style={{ fontWeight: 'bold' }}>HSS 266</span>.</p>
                <div className="contact-options">
                <h2>Contact Info :</h2>
                <ul>
                <li>Office : {user.office}</li>
                <li>Email : {user.email} </li>            
            </ul>
                </div>
            </div>
            );
};

export default Contact;