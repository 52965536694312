import React, { useEffect, useState } from 'react';
import axios from 'axios';





const useCourseAPI = () => {
    const [courses, setCourses] = useState([]);
    const [userCourses, setUserCourses] = useState([]);

    useEffect(() => {
        getCourses();
        getUserCourses();
    }, []);
    
    
    
    const deleteCourse = async (id) => {
      try{
          const response = await axios.delete(`${process.env.REACT_APP_API_URL}/usercourses/${id}`);
          console.log("course was deleted !")
      }catch(error){
          
          console.log("course delete failed !", error)          
      }  
        
    };
    

    const getCourses = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/courses`);
            setCourses(response.data);
        } catch (error) {
            console.error("Error fetching courses:", error);
        }

    };
    
        const getUserCourses = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/usercourses`);
            setUserCourses(response.data);
            console.log("User Courses retrieved : ",response.data)
        } catch (error) {
            console.error("Error fetching courses:", error);
        }

    };
    
        const updateCourse = async (courseId, courseData) => {
        try {
            const response = await axios.put(`${process.env.REACT_APP_API_URL}/usercourses/${courseId}`, courseData, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            console.log('Course updated successfully:', response.data);
            getUserCourses();  
            return response.data;
        } catch (error) {
            console.error('Failed to update course:', error);
            throw error;
        }
    };


    return {courses, updateCourse, userCourses, setUserCourses, getUserCourses, deleteCourse};

};

export default  useCourseAPI;