import React from 'react';
import axios from 'axios';





const useCourseAPI = (getUserCourses) => {



    const addCourse = async (courseId) => {
        try {
            const userCourse = {
                course: {id: courseId}
            };
   const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/usercourses`,
      userCourse,
      {
        headers: {
          'X-XSRF-TOKEN': axios.defaults.headers.common['X-XSRF-TOKEN']
        },
        withCredentials: true
      }
    );
    getUserCourses();

        } catch (error) {
            console.error('Error creating UserCourse:', error);
        }

    };

    return {addCourse};

};



export default useCourseAPI;