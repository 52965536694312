import React, {useEffect} from 'react';
import axios from 'axios';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Home from './components/home/Home';
import Navbar from './components/navbar/Navbar';
import Contact from './components/contact/Contact';
//import JuliaSetGenerator from './components/juliasetgenerator/JuliaSetGenerator';
import ImageDetail from './components/imagedetail/ImageDetail';
import ResearchAndExperience from './components/researchandexperience/ResearchAndExperience';
import ArtWorkManager from './components/artworkmanager/ArtWorkManager';
import Footer from './components/footer/Footer';
import Login from './components/login/Login';
import ExperienceManager from './components/experiencemanager/ExperienceManager';
import ResearchManager from './components/researchmanager/ResearchManager';
import AccountManager from './components/accountmanager/AccountManager';
import CourseManager from './components/coursemanager/CourseManager';
import Settings from './components/settings/Settings';
import Gallery from './components/gallery/Gallery';
import { useAuth } from './context/AuthContext';
import './App.css'


        const App = () => {
    const {isLoggedIn} = useAuth();

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/auth/csrf`)
                .then((response) => {
                    console.log("CSRF token set");
                })
                .catch((error) => {
                    console.error("Error setting CSRF token:", error);
                });
    }, []);

    return (
            <Router>
                <div className="app-container">
                    <Navbar/>
                    <main className="main-content">
                        <Routes>
                            <Route path="/" element={ < Home / > } />
                            <Route path="/contact" element={ < Contact / > } />
                            <Route path="/research" element={ < ResearchAndExperience / > } />
                            <Route path="/login" element={
                                                           isLoggedIn ? <Navigate to="/" /> : <Login />
                                } />
                                <Route path="/settings"  element={
                                                           isLoggedIn ? <Settings /> : <Navigate to="/" /> 
                                }  />
                                <Route path="/gallery" element={ < Gallery / > }/>
                                        <Route path="/image/:id" element={<ImageDetail />} />
            
            
                        </Routes>    
                    </main>
                    <Footer />
                </div>
            
            </Router>
                                            );
                                };

                                export default App;
