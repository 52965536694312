import React, {useState, useEffect} from 'react';
import axios from 'axios';
import cleanFileName from './../../../util/FileNameCleaner';







const useResearchAPI = () => {

    const getPresignedURL = async (fileName) => {
        const cleanedFileName = cleanFileName(fileName);
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/research/presign-url`, {
                params: {fileName: cleanedFileName},
                withCredentials: true
            });
            console.log(response.data);
            return response.data;
        } catch (error) {
            console.log("error retrieving url", error);
        }

    };

    const uploadToS3 = async (preSignedUrl, image) => {

        try {
            const response = await axios.put(preSignedUrl, image, {
                headers: {
                    'Content-Type': image.type
                }
            });

            console.log("Succesful upload");

        } catch (error) {
            console.log("error uploading to S3");
        }

    };

    const addResearch = async (research) => {
        try {
            console.log(research);
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/research`, research, {withCredentials: true});
            console.log(response.data);
        } catch (error) {
            console.log(error);
        }
    };


    return {addResearch, getPresignedURL, uploadToS3};
};



export default useResearchAPI;