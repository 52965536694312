import React, { useState } from 'react';
import useResearchAPI from './hooks/useResearchAPI';
import ResearchCreator from './../researchcreator/ResearchCreator';
import './ResearchManager.css';

const validDocumentTypes = [
  'application/pdf',
  'application/rtf',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
];

const ResearchManager = () => {
  const {
    researchList,
    updateResearch,
    deleteResearch,
    getPresignedURL,
    uploadToS3,
    getResearch
  } = useResearchAPI();

  const [editResearchId, setEditResearchId] = useState(null);
  const [formData, setFormData] = useState({
    title: '',
    citation: '',
    document: null,
    documentLink: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      if (file.size > 50 * 1024 * 1024) {
        alert('File size should be less than 50 MB.');
        setFormData((prevData) => ({ ...prevData, document: null }));
        return;
      }

      if (!validDocumentTypes.includes(file.type)) {
        alert('Please upload a valid document file (PDF, RTF, DOC, or DOCX).');
        setFormData((prevData) => ({ ...prevData, document: null }));
        return;
      }

      setFormData((prevData) => ({ ...prevData, document: file }));
    }
  };

  const handleEdit = (research) => {
    setEditResearchId(research.id);
    setFormData({
      title: research.title,
      citation: research.citation,
      document: null,
      documentLink: research.documentLink
    });
  };

  const handleSave = async () => {
    try {
      let updatedResearch;

      if (formData.document) {
        const urls = await getPresignedURL(formData.document.name);
        await uploadToS3(urls[0], formData.document);
        updatedResearch = {
          title: formData.title,
          citation: formData.citation,
          documentLink: urls[1]
        };
      } else {
        updatedResearch = {
          title: formData.title,
          citation: formData.citation,
          documentLink: formData.documentLink
        };
      }

      await updateResearch(editResearchId, updatedResearch);
      setEditResearchId(null);
      setFormData({ title: '', citation: '', document: null, documentLink: '' });
    } catch (error) {
      console.error('Error updating research entry:', error);
      alert('Failed to save changes. Please try again.');
    }
  };

  const handleCancel = () => {
    setEditResearchId(null);
    setFormData({ title: '', citation: '', document: null, documentLink: '' });
  };

  return (
    <div className="research-container">
      <ResearchCreator getResearch={getResearch} />

      <div className="research-table">
        <table className="table">
          <thead>
            <tr>
              <th>Title</th>
              <th>Citation</th>
              <th>Document</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {researchList.map((research) => (
              <tr key={research.id}>
                <td>
                  {editResearchId === research.id ? (
                    <input
                      type="text"
                      name="title"
                      value={formData.title}
                      onChange={handleChange}
                    />
                  ) : (
                    research.title
                  )}
                </td>
                <td>
                  {editResearchId === research.id ? (
                    <textarea
                      name="citation"
                      value={formData.citation}
                      onChange={handleChange}
                    />
                  ) : (
                    research.citation
                  )}
                </td>
                <td>
                  {editResearchId === research.id ? (
                    <div className="file-input-group">
                      <label htmlFor="document">Upload Document:</label>
                      <input
                        id="document"
                        type="file"
                        accept=".pdf, .rtf, .doc, .docx"
                        onChange={handleFileChange}
                      />
                      <p className="file-type-note">
                        Accepted file types: PDF, DOC, DOCX, RTF. Max size: 50 MB.
                      </p>
                    </div>
                  ) : (
                    <a href={research.documentLink} target="_blank" rel="noopener noreferrer">
                      View Document
                    </a>
                  )}
                </td>
                <td>
                  {editResearchId === research.id ? (
                    <>
                      <button className="btn-save" onClick={handleSave}>
                        Save
                      </button>
                      <button className="btn-cancel" onClick={handleCancel}>
                        Cancel
                      </button>
                      <button
                        className="btn-delete"
                        onClick={() => deleteResearch(research.id)}
                      >
                        Delete
                      </button>
                    </>
                  ) : (
                    <button className="btn-edit" onClick={() => handleEdit(research)}>
                      Edit
                    </button>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ResearchManager;
