import React, { useState } from 'react';
import useResearchAPI from './hooks/useResearchAPI';
import './ResearchCreator.css';

const validDocumentTypes = [
  'application/pdf',
  'application/rtf',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
];

const ResearchCreator = ({getResearch}) => {
    const { researchList, addResearch, updateResearch, deleteResearch, getPresignedURL, uploadToS3 } = useResearchAPI();

  const [formData, setFormData] = useState({
    title: '',
    citation: '',
    document: null,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      if (file.size > 50 * 1024 * 1024) {
        alert('File size should be less than 50 MB.');
        setFormData((prevData) => ({ ...prevData, document: null }));
        return;
      }

      if (!validDocumentTypes.includes(file.type)) {
        alert('Please upload a valid document file (PDF, RTF, DOC, or DOCX).');
        setFormData((prevData) => ({ ...prevData, document: null }));
        return;
      }

      setFormData((prevData) => ({ ...prevData, document: file }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {

      const urls = await getPresignedURL(formData.document.name);
      await uploadToS3(urls[0], formData.document);
      const researchEntry = {
        title: formData.title,
        citation: formData.citation,
        documentLink: urls[1]
      };
      await addResearch(researchEntry);

      setFormData({
        title: '',
        citation: '',
        document: null,
      });
      getResearch();
    } catch (error) {
      console.error('Error adding research entry:', error);
      alert('There was an error. Please try again.');
    }
  };

  return (
    <div className="research-form">
      <h1>Add Research Entry</h1>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="title">Title:</label>
          <input
            id="title"
            name="title" 
            type="text"
            value={formData.title}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="citation">Citation:</label>
          <textarea
            id="citation"
            name="citation" 
            value={formData.citation}
            onChange={handleChange}
            rows="4"
            className="textarea-citation"
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="document">
            Upload Document (PDF, DOC, DOCX, RTF, max 50 MB):
          </label>
          <input
            id="document"
            name="document"
            type="file"
            accept=".pdf, .rtf, .doc, .docx"
            onChange={handleFileChange}
            required
          />
        </div>
        <button type="submit" className="submit-button">
          Add Research
        </button>
      </form>
    </div>
  );
};

export default ResearchCreator;
