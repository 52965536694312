import React from 'react';
import ImageCard from './../imagecard/ImageCard';
import useUserAPI from './hooks/useUserAPI';
import Loading from './../loading/Loading';
import './Home.css';


const Home = () => {
    const {user, courses} = useUserAPI();
    
    if(!user || !user.profilePicture){
        return <Loading />;
    }

    return (
            <div className="home-content">
            
                <h1>Sean Hadley Mathematics </h1>
                <div className="bio">
                    <ImageCard image={ {id: 1, profilePicture: true,  imageLink: user.profilePicture, title: "Picture of Sean"} } />
                    <div className='bio-text-content'>
                        <h2>About Sean </h2>
                        <p>
                            {user.bio}
                        </p>
            
                    </div>
            
                </div>
                <div className='current-courses'>
                    <h3>Courses Sean is currently teaching</h3>            
            
                    <table className="table">
                        <thead>
                            <tr>
                                <th width="20%">Course Name</th>
                                <th width="15%">Course Number</th>
                            </tr>
                        </thead>
                        <tbody>
                            {courses
                                        .filter(course => course.roles.includes("TEACHER") && course.active)
                                        .map(course => (
                                        <tr key={course.id}>
                                            <td>{course.course.courseName}</td>
                                            <td>{course.course.courseNumber}</td>
                                        </tr>
                                                    ))
                            }
                        </tbody>
                    </table>
            
                </div>           
            
                <div className='tutor-courses'>
            
                    <h5>Courses Sean tutors for</h5>                        
                    <table className="table">
                        <thead>
                            <tr>
                                <th width="20%">Course Name</th>
                                <th width="15%">Course Number</th>
                            </tr>
                        </thead>
                        <tbody>
                            {courses
                                        .filter(course => course.roles.includes("TUTOR") && course.active)
                                        .map(course => (
                                        <tr key={course.id}>
                                            <td>{course.course.courseName}</td>
                                            <td>{course.course.courseNumber}</td>
                                        </tr>
                                                    ))
                            }
                        </tbody>
                    </table>
                </div>                                    
            
            
            
            </div>
            );


};



export default Home;