
export const MAX_SIZE = 25 * 1024 * 1024; // 25MB in bytes

export const validDocumentTypes = [
    'application/pdf',
    'application/rtf',
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
];
export const validImageTypes = ['image/jpeg', 'image/png', 'image/gif', 'image/webp', 'image/heic', 'image/heif'];





export const isValidEmail = (email, errors) => {
    const emailRegExp = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/; // regex for email validity
    if (!emailRegExp.test(String(email))) {
        errors.push("Invalid email.");
    }
};

/**
 * password validation util takes array of errors and pushes field specific error msgs
 * @param  password
 * @param  errors
 */
export const isValidPassword = (password, errors) => {
    if (password.length < 6 || password.length > 16) {
        errors.push("Contain 6 to 16 characters");
    }
    /*
    if (!/[A-Z]/.test(password)) {
        errors.push("Contain at least 1 uppercase letter");
    }
    if (!/[a-z]/.test(password)) {
        errors.push("Contain at least 1 lowercase letter");
    }
    if (!/\d/.test(password)) {
        errors.push("Contain at least 1 number");
    }*/
};

export const isValidTextFieldEntry = (textEntry, errors) => {
    if (textEntry.length > 225) {
        errors.push("Text Entry must be less than 225 characters.");
    }
};

export const isValidTitle = (title, errors) => {
    if (!title) {
        errors.push("Title must not be empty .");
    }
    if (title.length > 64) {
        errors.push("Title Length must be less than 65 characters");
    }
};

export const isValidName = (name, errors) => {
    const nameRegex = /^[A-Za-z]{2,16}$/;
    if (!nameRegex.test(name)) {
        errors.push(
                "Must have between 2 and 16 letters with no spaces or special characters",
                );
    }
};

export const isValidConfirmPassword = (password, confirmPassword, errors) => {
    if (password !== confirmPassword) {
        errors.push("password and confirm password must match");
    }
};


export const isValidTimeRange = (startTime, endTime, errors) => {
    const startTimeSet = startTime && startTime.trim() !== '';
    const endTimeSet = endTime && endTime.trim() !== '';

    if ((startTimeSet && !endTimeSet) || (!startTimeSet && endTimeSet)) {
        errors.push('Start Time and End Time must both be set or both be empty.');
    }
};