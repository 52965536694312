import React, {useEffect, useState} from 'react';
import axios from 'axios';


const useUserAPI = () => {
    const [user, setUser] = useState({
        experience: [],
        research: [],
    });




    useEffect(() => {
        getUserDetails();
    }, []);

    const getUserDetails = async () => {

        try {
            await axios.get(`${process.env.REACT_APP_API_URL}/user`)
                    .then(response => {
              //  console.log(response.data);
                        setUser(response.data);
                    }).catch(error => {
                console.error("There was an error", error);
            });

        } catch (error) {

        }

    };

    return { user};

};


export default useUserAPI;