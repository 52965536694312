import React, {useState} from 'react';
import Loading from './../loading/Loading';
import CarouselContainer from './../carouselcontainer/CarouselContainer';
import SlideInImageContainer from './../slideinimagecontainer/SlideInImageContainer';
import useArtWorkAPI from './hooks/useArtWorkAPI';
import ImageCard from './../imagecard/ImageCard';
import JuliaSetGenerator from './../juliasetgenerator/JuliaSetGenerator';
import './Gallery.css';





const Gallery = () => {
    const {artWorkList} = useArtWorkAPI();

    if (!artWorkList) {
        return <Loading/>;
    }

    return (<div className="gallery-content" >
        <h1>Gallery </h1>
        <p>A show case of art work created by Sean throughout the years </p>
         {artWorkList ? (<>
    <CarouselContainer imageList={artWorkList} />      
    
        <div className="slide-in-container">
            {artWorkList.map((image, index) => (
                        <SlideInImageContainer
                            key={`slide-${index}`}
                            src={image.imageLink}
                            alt={image.title}
                            />
                                ))}
        </div>
    
        <div className="grid-container">
            {artWorkList.map((image, index) => (
                        <ImageCard key={`slide-${index}`} image={image}
                                   />
                                ))}
        </div>
    </>
)  : (<Loading />) }
        <JuliaSetGenerator />
    </div>);

};

export default Gallery;