// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.course-selector {
    margin: 20px auto;
    padding: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    background-color: #fff;
    text-align: center;
}

.course-selector select {
    width: 80%;
    padding: 10px;
    margin: 10px 0;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #f5f5f5;
    color: #333;
    font-size: 16px;
    cursor: pointer;
}

.course-selector select:focus {
    outline: none;
    border-color: #888;
}

.select-button {
    padding: 10px;
    width: 50%;
    background-color: #2c2c2c;
    color: #c0c0c0;
    border: 1px solid #696969;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 15px;
    transition: background-color 0.3s, color 0.3s;
}

.select-button:hover {
    background-color: #505050;
    color: #e0e0e0;
}
`, "",{"version":3,"sources":["webpack://./src/components/courseselector/CourseSelector.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,aAAa;IACb,uCAAuC;IACvC,kBAAkB;IAClB,sBAAsB;IACtB,kBAAkB;AACtB;;AAEA;IACI,UAAU;IACV,aAAa;IACb,cAAc;IACd,sBAAsB;IACtB,kBAAkB;IAClB,yBAAyB;IACzB,WAAW;IACX,eAAe;IACf,eAAe;AACnB;;AAEA;IACI,aAAa;IACb,kBAAkB;AACtB;;AAEA;IACI,aAAa;IACb,UAAU;IACV,yBAAyB;IACzB,cAAc;IACd,yBAAyB;IACzB,kBAAkB;IAClB,eAAe;IACf,gBAAgB;IAChB,6CAA6C;AACjD;;AAEA;IACI,yBAAyB;IACzB,cAAc;AAClB","sourcesContent":[".course-selector {\n    margin: 20px auto;\n    padding: 20px;\n    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);\n    border-radius: 8px;\n    background-color: #fff;\n    text-align: center;\n}\n\n.course-selector select {\n    width: 80%;\n    padding: 10px;\n    margin: 10px 0;\n    border: 1px solid #ccc;\n    border-radius: 4px;\n    background-color: #f5f5f5;\n    color: #333;\n    font-size: 16px;\n    cursor: pointer;\n}\n\n.course-selector select:focus {\n    outline: none;\n    border-color: #888;\n}\n\n.select-button {\n    padding: 10px;\n    width: 50%;\n    background-color: #2c2c2c;\n    color: #c0c0c0;\n    border: 1px solid #696969;\n    border-radius: 4px;\n    cursor: pointer;\n    margin-top: 15px;\n    transition: background-color 0.3s, color 0.3s;\n}\n\n.select-button:hover {\n    background-color: #505050;\n    color: #e0e0e0;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
