import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from './../../context/AuthContext';
import './Navbar.css';

const Navbar = () => {
    const { isLoggedIn, logout, user, setUser, getUserDetails} = useAuth();
    const [dropdownOpen, setDropdownOpen] = useState(false);

useEffect(() => {
    getUserDetails();
}, [isLoggedIn, setUser] );

    const toggleDropdown = () => {
        setDropdownOpen(!dropdownOpen);
    };

    const handleLogout = () => {
        logout();
        setDropdownOpen(false); 
    };

    return (
        <nav className='navbar'>
            <div className="favicon-container">
                {isLoggedIn ? (
                    <img src="/favicon-32.png" alt="Site Icon" className="favicon-image" />
                ) : (
                    <Link to="/login">
                        <img src="/favicon-32.png" alt="Site Icon" className="favicon-image" />
                    </Link>
                )}
            </div>
            <div className="nav-links">
                <Link to="/">Home</Link>
                <Link to="/contact">Contact</Link>
                <Link to="/research">Research and Experience</Link>
                <Link to="/gallery">Gallery</Link>
                {isLoggedIn && (
                    <div className="user-menu" onMouseEnter={toggleDropdown} onMouseLeave={toggleDropdown}>
                        <span className="user-greeting">Hi, {user.firstName}</span>
                        {dropdownOpen && (
                            <div className="dropdown">
                                <Link to="/settings" className="dropdown-item">Settings</Link>
                                <button onClick={handleLogout} className="dropdown-item">Logout</button>
                            </div>
                        )}
                    </div>
                )}
            </div>
        </nav>
    );
};

export default Navbar;
