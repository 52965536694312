// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.image-container {
  width: 100%;
  max-height: calc(
    (100vh / 3) * 2
  ); 
  overflow: hidden; 
  opacity: 0;
  transform: translateY(20px);
  transition:
    opacity 0.5s ease-out,
    transform 0.5s ease-out;
}

.image-container.slide-in {
  opacity: 1;
  transform: translateY(0);
}

.image-container img {
  width: 100%;
  height: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/components/slideinimagecontainer/SlideInImageContainer.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX;;GAEC;EACD,gBAAgB;EAChB,UAAU;EACV,2BAA2B;EAC3B;;2BAEyB;AAC3B;;AAEA;EACE,UAAU;EACV,wBAAwB;AAC1B;;AAEA;EACE,WAAW;EACX,YAAY;AACd","sourcesContent":[".image-container {\n  width: 100%;\n  max-height: calc(\n    (100vh / 3) * 2\n  ); \n  overflow: hidden; \n  opacity: 0;\n  transform: translateY(20px);\n  transition:\n    opacity 0.5s ease-out,\n    transform 0.5s ease-out;\n}\n\n.image-container.slide-in {\n  opacity: 1;\n  transform: translateY(0);\n}\n\n.image-container img {\n  width: 100%;\n  height: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
