// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.social-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 5px; 
}

.social-icon-svg {
  height: 24px; 
  width: 24px;  
}

.social-icon:hover .social-icon-svg {
  fill: #cccccc; 
}
`, "",{"version":3,"sources":["webpack://./src/components/socialicon/SocialIcon.css"],"names":[],"mappings":"AAAA;EACE,oBAAoB;EACpB,mBAAmB;EACnB,uBAAuB;EACvB,YAAY;AACd;;AAEA;EACE,YAAY;EACZ,WAAW;AACb;;AAEA;EACE,aAAa;AACf","sourcesContent":[".social-icon {\n  display: inline-flex;\n  align-items: center;\n  justify-content: center;\n  padding: 5px; \n}\n\n.social-icon-svg {\n  height: 24px; \n  width: 24px;  \n}\n\n.social-icon:hover .social-icon-svg {\n  fill: #cccccc; \n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
