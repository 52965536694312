// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.header {
  background-color: #7e22ce;
  padding: 10px 20px;
  color: white;
  text-align: center;
}

.header a {
  color: white;
  text-decoration: none;
  margin: 0 15px;
}

.gallery-content {
  text-align: center;
  align-content: center;
  padding: 20px;
}

.gallery-content h1 {
  color: silver;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  font-size: 2.5em;
  margin-bottom: 20px;
}

.gallery-content p {
  color: #333;
  font-size: 1.2em;
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 16px;
  padding: 16px;
  margin-top: 20px;
}

.slide-in-container {
  margin-top: 20px;
  display: flex;
  flex-direction: column; 
  gap: 200px;
}

.carousel-container {
  width: 80%;
  height: 300px;
  border-radius: 15px; 
  margin: 40px auto; 
  padding: 20px 0; 
  border: 2px solid #ccc; 
  background-color: #fff;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); 
}
`, "",{"version":3,"sources":["webpack://./src/components/gallery/Gallery.css"],"names":[],"mappings":"AAAA;EACE,8BAA8B;EAC9B,SAAS;EACT,UAAU;EACV,sBAAsB;AACxB;;AAEA;EACE,yBAAyB;EACzB,kBAAkB;EAClB,YAAY;EACZ,kBAAkB;AACpB;;AAEA;EACE,YAAY;EACZ,qBAAqB;EACrB,cAAc;AAChB;;AAEA;EACE,kBAAkB;EAClB,qBAAqB;EACrB,aAAa;AACf;;AAEA;EACE,aAAa;EACb,2CAA2C;EAC3C,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,WAAW;EACX,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,4DAA4D;EAC5D,SAAS;EACT,aAAa;EACb,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;EAChB,aAAa;EACb,sBAAsB;EACtB,UAAU;AACZ;;AAEA;EACE,UAAU;EACV,aAAa;EACb,mBAAmB;EACnB,iBAAiB;EACjB,eAAe;EACf,sBAAsB;EACtB,sBAAsB;EACtB,gBAAgB;EAChB,wCAAwC;AAC1C","sourcesContent":["body {\n  font-family: Arial, sans-serif;\n  margin: 0;\n  padding: 0;\n  box-sizing: border-box;\n}\n\n.header {\n  background-color: #7e22ce;\n  padding: 10px 20px;\n  color: white;\n  text-align: center;\n}\n\n.header a {\n  color: white;\n  text-decoration: none;\n  margin: 0 15px;\n}\n\n.gallery-content {\n  text-align: center;\n  align-content: center;\n  padding: 20px;\n}\n\n.gallery-content h1 {\n  color: silver;\n  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);\n  font-size: 2.5em;\n  margin-bottom: 20px;\n}\n\n.gallery-content p {\n  color: #333;\n  font-size: 1.2em;\n}\n\n.grid-container {\n  display: grid;\n  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));\n  gap: 16px;\n  padding: 16px;\n  margin-top: 20px;\n}\n\n.slide-in-container {\n  margin-top: 20px;\n  display: flex;\n  flex-direction: column; \n  gap: 200px;\n}\n\n.carousel-container {\n  width: 80%;\n  height: 300px;\n  border-radius: 15px; \n  margin: 40px auto; \n  padding: 20px 0; \n  border: 2px solid #ccc; \n  background-color: #fff;\n  overflow: hidden;\n  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); \n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
