import React from 'react';
import axios from 'axios';




const useArtWorkAPI = () => {
    
    const getArtWorkById = async (id) => {
        try{
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/artwork/${id}`, {withCredentials: true});
        //    console.log(response.data);
            return response.data;
        }catch(error){
            console.error("Error fetching artwork by ID:", error);
        }
        
    };
    
    const updateArtWork = async (id, artwork) =>{
      try{
          const response = await axios.put(`${process.env.REACT_APP_API_URL}/artwork/${id}`, artwork, {withCredentials:true});
          return response.data;
      }catch(error){
          console.log('Error updating artwork : ', error);
      }  
      
    };
    
    const deleteArtWork = async (id) =>{
        
        try{
            const response = await axios.delete(`${process.env.REACT_APP_API_URL}/artwork/${id}`, {withCredentials:true});
            console.log("success");
        }catch(error){
        console.log("Error deleting artwork : ",error);            
        }

    };
    
    return {updateArtWork, deleteArtWork, getArtWorkById};
};


export default useArtWorkAPI;