// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer {
    background-color: #1e1e1e;
    color: #ffffff;
    text-align: center;
    padding: 10px 0;
    box-shadow: 0 -4px 8px rgba(0, 0, 0, 0.1);
    width: 100%;
    position: relative;

    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
}


.footer-social {
    display: flex;
    gap: 20px;
}

.footer-social a {
    color: #ffffff;
    text-decoration: none;
    font-size: 1em;
    position: relative;
    background: linear-gradient(90deg, silver, gray); 
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    transition: background-position 0.5s ease;
}

.footer-social a:hover {
    background: radial-gradient(
        circle at var(--x) var(--y),
        rgba(255, 255, 255, 0.5),
        rgba(255, 255, 255, 0)
        );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

`, "",{"version":3,"sources":["webpack://./src/components/footer/Footer.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,cAAc;IACd,kBAAkB;IAClB,eAAe;IACf,yCAAyC;IACzC,WAAW;IACX,kBAAkB;;IAElB,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,SAAS;AACb;;;AAGA;IACI,aAAa;IACb,SAAS;AACb;;AAEA;IACI,cAAc;IACd,qBAAqB;IACrB,cAAc;IACd,kBAAkB;IAClB,gDAAgD;IAChD,6BAA6B;IAC7B,oCAAoC;IACpC,yCAAyC;AAC7C;;AAEA;IACI;;;;SAIK;IACL,6BAA6B;IAC7B,oCAAoC;AACxC","sourcesContent":[".footer {\n    background-color: #1e1e1e;\n    color: #ffffff;\n    text-align: center;\n    padding: 10px 0;\n    box-shadow: 0 -4px 8px rgba(0, 0, 0, 0.1);\n    width: 100%;\n    position: relative;\n\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    gap: 20px;\n}\n\n\n.footer-social {\n    display: flex;\n    gap: 20px;\n}\n\n.footer-social a {\n    color: #ffffff;\n    text-decoration: none;\n    font-size: 1em;\n    position: relative;\n    background: linear-gradient(90deg, silver, gray); \n    -webkit-background-clip: text;\n    -webkit-text-fill-color: transparent;\n    transition: background-position 0.5s ease;\n}\n\n.footer-social a:hover {\n    background: radial-gradient(\n        circle at var(--x) var(--y),\n        rgba(255, 255, 255, 0.5),\n        rgba(255, 255, 255, 0)\n        );\n    -webkit-background-clip: text;\n    -webkit-text-fill-color: transparent;\n}\n\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
