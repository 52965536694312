import React, { useState } from 'react';
import axios from 'axios';
import useArtWorkAPI from './hooks/useArtWorkAPI';
import useForm from './hooks/useForm';
import { validImageTypes, MAX_SIZE } from './../../util/ValidationUtils';

import './ArtWorkManager.css';

const ArtWorkManager = () => {
    const {getPresignedURL, uploadToS3, addArtWork} = useArtWorkAPI();
    const initialValues= {
        title: '',
        description: '',
        image: null,
    };
    
    const { formData, setFormData, handleChange, errors, setErrors,setHasChanges, hasChanges } = useForm(initialValues);
    const [preview, setPreview] = useState(null);
    const [progress, setProgress] = useState(0);

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        setProgress(0);
        setErrors('');
        setPreview(null);

        if (file) {
            if (file.size > MAX_SIZE) {
                setErrors('File size must be under 25MB');
                return;
            }

            if (!validImageTypes.includes(file.type)) {
                setErrors('Only image files (JPEG, PNG, GIF, WEBP) are allowed');
                return;
            }

            setFormData((prevFormData) => ({
                    ...prevFormData,
                    image: file,
                }));
            setPreview(URL.createObjectURL(file));
        }
    };


    const handleSubmit = async (e) => {
        e.preventDefault();
        const { title, description, image } = formData;
        const preSignedUrl = await getPresignedURL(image.name);
        const newArtWork = {
            title,
            description,
            imageLink: preSignedUrl[1]
        };
        console.log(preSignedUrl);
        await uploadToS3(preSignedUrl[0], image, (progress) => setProgress(progress));
        await addArtWork(newArtWork);
        setProgress(0);
        setFormData({
            title: '',
            description: '',
            image: null
        });
        setPreview(null);

    };

    return (
            <div className="artwork-manager">
                <div className="preview-container">
                    <h1>Preview : </h1>
                    {progress > 0 && (
                                    <div className="progress-bar">
                                        <div className="progress" style={{width: `${progress}%`}}>
                                            {progress}%
                                        </div>
                                    </div>
                                        )}
                    {preview ? (
                            <div className="image-detail">
                                <div className="image-wrapper">
                                    <img src={preview} alt="Preview" className="full-size-image" />
                                </div>
                                <div className="image-info">
                                    <div className="image-header">
                                        <h2 className="image-title">{formData.title || 'Untitled'}</h2>
                                        <p className="image-date">Pending Upload</p>
                                    </div>
                                    <p className="image-description">{formData.description || 'No description'}</p>
                                </div>
                            </div>
                                ) : (
                            <p className="preview-placeholder">Select an image to preview</p>
                                )}
                </div>
                <div className="upload-container">
                    <form onSubmit={handleSubmit} className="upload-form">
                    {errors.title && errors.title.length > 0 && (
  <div className="error-message">{errors.title[0]}</div>
)}
                        <input
                            type="text"
                            name="title"
                            placeholder="Title"
                            value={formData.title}
                            onChange={handleChange}
                            required
                            className="input-title"
                            />
                        <textarea
                            placeholder="Description"
                            name="description"
                            value={formData.description}
                            onChange={handleChange}
                            className="input-description"
                            />
                        <input
                            type="file"
                            name="image"
                            accept="image/*"
                            onChange={handleImageChange}
                            required
                            className="input-file"
                            />
                        <button type="submit" className="upload-button">
                            Upload Artwork
                        </button>
                    </form>
                </div>
            </div>
            );
};

export default ArtWorkManager;
