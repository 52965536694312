import React from 'react';
import useUserAPI from './hooks/useUserAPI';
import './ResearchAndExperience.css';

const ResearchAndExperience = () => {
    const {user} = useUserAPI();
    return (
        <div className="research-experience-content">
            <h1>{user.firstName} {user.lastName} - Research & Experience</h1>
            
            {/* Research Interests */}
            <div className="research-interests">
                <h3>Research Interests</h3>
                <p>{user.researchInterests}</p>
            </div>
            
            {/* Experience Section */}
           <div className="experience">
    <h3>Experience</h3>
    <ul className="experience-list">
        {user.experience.map((exp) => (
            <li key={exp.id} className="experience-item">
                <strong>Title:</strong> {exp.title}
                <br />
                <strong>Year:</strong> {exp.year}
                <br />
                <strong>Season:</strong> {exp.season}
                <br />
                <strong>Employer:</strong> {exp.employer}
                <br />
                <strong>Description:</strong> {exp.description}
            </li>
        ))}
    </ul>
</div>

            
            {/* Research Papers Section */}
            <div className="research-papers">
                <h3>Research Papers</h3>
<ul className="research-list">
        {user.research.map((paper) => (
            <li key={paper.id} className="research-item">
                <strong>Title:</strong> {paper.title}
                <br />
                <strong>Citation:</strong> {paper.citation}
                <br />
                <strong>Link:</strong> <a href={paper.documentLink} target="_blank" rel="noopener noreferrer">
                    View Document
                </a>
            </li>
        ))}
    </ul>
            </div>
        </div>
    );
};

export default ResearchAndExperience;
