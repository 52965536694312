import React, { useState, useEffect } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { useAuth }  from './../../context/AuthContext';
import useArtWorkAPI from './hooks/useArtWorkAPI';
import './ImageDetail.css';

const ImageDetail = () => {
    const {id} = useParams();
    const location = useLocation();
    const navigate = useNavigate();
    const {isLoggedIn} = useAuth();
    const {updateArtWork, deleteArtWork, getArtWorkById} = useArtWorkAPI();
    const [image, setImage] = useState(location.state?.image);
    const [isEditMode, setIsEditMode] = useState(false);
    const [formData, setFormData] = useState({
        title: image?.title || '',
        description: image?.description || '',
        imageLink: image?.imageLink || ''
    });

    useEffect(() => {
        const getImg = async () => {
            if (!image) { // Only get if image is not already set
                const img = await getArtWorkById(id);
                if (img) {
                    setImage(img);
                    setFormData({
                        title: img.title,
                        description: img.description,
                        imageLink: img.imageLink
                    });
                }
            }
        };
        getImg();
    }, [id, image, getArtWorkById]);

    const handleEdit = () => setIsEditMode(true);

    const handleSave = async () => {
        try {
            const updatedImg = await updateArtWork(id, formData);
            if (updatedImg) {
                setImage(updatedImg);
                setFormData(updatedImg);
            }
            setIsEditMode(false);
        } catch (error) {
            console.error("Error saving artwork:", error);
        }
    };


    const handleCancel = () => {
        setFormData({title: image.title, description: image.description, imageLink: image.imageLink});
        setIsEditMode(false);
    };

    const handleDelete = () => {
        deleteArtWork(id);
        console.log(`Deleted artwork with ID: ${id}`);
        navigate(-1);
    };

    const handleChange = (e) => {
        const {name, value} = e.target;
        setFormData(prev => ({...prev, [name]: value}));
    };

    if (!image) {
        return <div>Image not found</div>;
    }

    return (
            <div className="image-detail">
                <button onClick={() => navigate(-1)} className="go-back-button">Go Back</button>
                <div className="image-wrapper">
                    <img src={image.imageLink} alt={image.title} className="full-size-image" />
                </div>
                <div className="image-info">
                    <div className="image-header">
                        {isEditMode ? (
                            <input
                                type="text"
                                name="title"
                                value={formData.title}
                                onChange={handleChange}
                                className="editable-title"
                                />
                                        ) : (
                            <h2 className="image-title">{image.title}</h2>
                                    )}
                        <p className="image-date">{image.date}</p>
                    </div>
                    {isEditMode ? (
                            <textarea
                                name="description"
                                value={formData.description}
                                onChange={handleChange}
                                className="editable-description"
                                />
                                    ) : (
                            <p className="image-description">{image.description}</p>
                                )}
                    {isLoggedIn && (
                            <div className="button-group">
                                {isEditMode ? (
                                                        <>
                                                        <button onClick={handleSave} className="btn-save">Save</button>
                                                        <button onClick={handleCancel} className="btn-cancel">Cancel</button>
                                                        <button onClick={handleDelete} className="btn-delete">Delete</button>
                                                        </>
                                                            ) : (
                                                <button onClick={handleEdit} className="btn-edit">Edit</button>
                                                    )}
                            </div>
                                )}
                </div>
            </div>
            );
};

export default ImageDetail;
