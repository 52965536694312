import React from 'react';
import { useInView } from 'react-intersection-observer';
import './SlideInImageContainer.css';

const SlideInImageContainer = ({ src, alt }) => {
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.4
  });

  return (
    <div className={`image-container ${inView ? 'slide-in' : ''}`} ref={ref}>
      <img src={src} alt={alt} />
    </div>
  );
};

export default SlideInImageContainer;
