import React, { createContext, useContext, useState, useEffect } from 'react';
import Loading from './../components/loading/Loading';
import axios from 'axios';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [user, setUser] = useState({});
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const initializeAuth = async () => {
            await checkLoginStatus();
            await getUserDetails();
            setIsLoading(false); 
        };
        
        initializeAuth();
    }, []);

    const getUserDetails = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/user`);
            setUser(response.data);
           // console.log(response.data);
        } catch (error) {
            console.error("There was an error", error);
        }
    };

    const checkLoginStatus = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/auth/checkSession`, { withCredentials: true });
            setIsLoggedIn(response.status === 200);
        } catch (error) {
            setIsLoggedIn(false);
        }
    };

    const logout = async () => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/auth/logout`, {}, {
                withCredentials: true
            });
            if (response.status === 200) {
                setIsLoggedIn(false);
            }
        } catch (error) {
            console.error('Logout Failure:', error);
        }
    };

    return (
        <AuthContext.Provider value={{ isLoggedIn, setIsLoggedIn, user, getUserDetails, logout }}>
            {isLoading ? <Loading /> : children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => useContext(AuthContext);
