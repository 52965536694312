// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Container for the sidebar and content */
.account-container {
    display: flex;
    width: 90%;
    height: 100vh; 
    overflow: hidden;
}

/* Sidebar styling */
.sidebar {
    max-width: 40%; 
    min-width: 20%;
    background-color: #2c2c2c; 
    color: #333; 
    display: flex;
    flex-direction: column;
    padding: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px; 
}

/* Button styling for sidebar */
.sidebar button {
    background-color: #e0e0e0;
    color: #333; 
    border: 1px solid #ccc; 
    padding: 12px 15px;
    margin-bottom: 10px;
    font-size: 16px;
    border-radius: 4px;
    cursor: pointer;
    text-align: left;
    transition:
        background-color 0.3s ease,
        color 0.3s ease;
}

.sidebar button:hover {
    background-color: #d3d3d3; 
    color: #2c2c2c; 
}

.sidebar button:focus {
    outline: none;
    border-color: #7e22ce; 
    box-shadow: 0 0 5px rgba(126, 34, 206, 0.3); 
}

/* Content styling */
.content {
    flex-grow: 1;
    padding: 10px;
    background-color: #c0c0c0; 
    color: #333; 
    overflow-y: auto;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); 
}
`, "",{"version":3,"sources":["webpack://./src/components/settings/Settings.css"],"names":[],"mappings":"AAAA,0CAA0C;AAC1C;IACI,aAAa;IACb,UAAU;IACV,aAAa;IACb,gBAAgB;AACpB;;AAEA,oBAAoB;AACpB;IACI,cAAc;IACd,cAAc;IACd,yBAAyB;IACzB,WAAW;IACX,aAAa;IACb,sBAAsB;IACtB,aAAa;IACb,uCAAuC;IACvC,kBAAkB;AACtB;;AAEA,+BAA+B;AAC/B;IACI,yBAAyB;IACzB,WAAW;IACX,sBAAsB;IACtB,kBAAkB;IAClB,mBAAmB;IACnB,eAAe;IACf,kBAAkB;IAClB,eAAe;IACf,gBAAgB;IAChB;;uBAEmB;AACvB;;AAEA;IACI,yBAAyB;IACzB,cAAc;AAClB;;AAEA;IACI,aAAa;IACb,qBAAqB;IACrB,2CAA2C;AAC/C;;AAEA,oBAAoB;AACpB;IACI,YAAY;IACZ,aAAa;IACb,yBAAyB;IACzB,WAAW;IACX,gBAAgB;IAChB,kBAAkB;IAClB,uCAAuC;AAC3C","sourcesContent":["/* Container for the sidebar and content */\n.account-container {\n    display: flex;\n    width: 90%;\n    height: 100vh; \n    overflow: hidden;\n}\n\n/* Sidebar styling */\n.sidebar {\n    max-width: 40%; \n    min-width: 20%;\n    background-color: #2c2c2c; \n    color: #333; \n    display: flex;\n    flex-direction: column;\n    padding: 20px;\n    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);\n    border-radius: 8px; \n}\n\n/* Button styling for sidebar */\n.sidebar button {\n    background-color: #e0e0e0;\n    color: #333; \n    border: 1px solid #ccc; \n    padding: 12px 15px;\n    margin-bottom: 10px;\n    font-size: 16px;\n    border-radius: 4px;\n    cursor: pointer;\n    text-align: left;\n    transition:\n        background-color 0.3s ease,\n        color 0.3s ease;\n}\n\n.sidebar button:hover {\n    background-color: #d3d3d3; \n    color: #2c2c2c; \n}\n\n.sidebar button:focus {\n    outline: none;\n    border-color: #7e22ce; \n    box-shadow: 0 0 5px rgba(126, 34, 206, 0.3); \n}\n\n/* Content styling */\n.content {\n    flex-grow: 1;\n    padding: 10px;\n    background-color: #c0c0c0; \n    color: #333; \n    overflow-y: auto;\n    border-radius: 8px;\n    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); \n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
