import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import Loading from '../loading/Loading';
import './ImageCard.css';

const ImageCard = ({ image }) => {
    const [isVisible, setIsVisible] = useState(false);
    const imageRef = useRef(null);

    const navigate = useNavigate();

    useEffect(() => {
        const observer = new IntersectionObserver(
                (entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    setIsVisible(true);
                    observer.disconnect();
                }
            });
        },
                {
                    rootMargin: '0px',
                    threshold: 0.1
                },
                );

        const currentImageRef = imageRef.current;
        if (currentImageRef) {
            observer.observe(currentImageRef);
        }

        return () => {
            if (currentImageRef) {
                observer.unobserve(currentImageRef);
            }
        };
    }, []);


    if (image.profilePicture) {
        return (
                <div
                    className="grid-item"
                    ref={imageRef}
                    style={{backgroundImage: isVisible ? `url(${image.imageLink})` : 'none'}}
                    >
                    {isVisible ? <img src={image.imageLink} alt={image.title}  /> : <Loading />}
                </div>
                );
    }



    return (
            <div
                className="grid-item"
                ref={imageRef}
                style={{backgroundImage: isVisible ? `url(${image.imageLink})` : 'none'}}
                >
                {isVisible ? <Link to={`/image/${image.id}`} key={image.id} state={{image}}>
                <img src={image.imageLink} alt={image.title}  />
                </Link> : <Loading />}
            </div>
            );
};

export default ImageCard;
