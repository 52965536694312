import React, { useState } from 'react';
import useExperienceAPI from './hooks/useExperienceAPI';
import ExperienceCreator from './../experiencecreator/ExperienceCreator';
import './ExperienceManager.css';

const ExperienceManager = () => {
    const {addExperience, deleteExperience, updateExperience, experienceList, getExperience} = useExperienceAPI();
    const [editExperienceId, setEditExperienceId] = useState(null);
    const [formData, setFormData] = useState({title: '', description: '', year: '', season: '', employer: ''});



    const currentYear = new Date().getFullYear();
    const years = Array.from({length: 50}, (_, i) => currentYear - i); // Last 50 years


    const handleEdit = (experience) => {
        setEditExperienceId(experience.id);
        setFormData(experience);
    };

    const handleChange = (e) => {
        const {name, value} = e.target;
        setFormData(prevData => ({...prevData, [name]: value}));
    };

    const handleSave = (e) => {
        e.preventDefault();
        console.log(formData);
        updateExperience(editExperienceId, formData);
        console.log(formData);
        setEditExperienceId(null);
    };

    const handleCancel = () => {
        setEditExperienceId(null);
    };

    const handleDelete = (id) => {
        console.log("Delete : ", id);
        deleteExperience(id);
        if (editExperienceId === id) {
            setEditExperienceId(null);
        }
    };



    return (
            <div className="experience-container">
                <h1>Add Experience</h1>
                <ExperienceCreator getExperience={getExperience} addExperience={addExperience} />
                <div className="experience-table">
                    <table className="table">
                        <thead>
                            <tr>
                                <th>Title</th>
                                <th>Description</th>
                                <th>Year</th>
                                <th>Season</th>
                                <th>Employer</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {experienceList.map(experience => (
                                        <tr key={experience.id}>
                                            <td>
                                                {editExperienceId === experience.id ? (
                                                                                <input
                                                                                    type="text"
                                                                                    name="title"
                                                                                    value={formData.title}
                                                                                    onChange={handleChange}
                                                                                    />
                                                                                    ) : experience.title}
                                            </td>
                                            <td>
                                                {editExperienceId === experience.id ? (
                                                                        <textarea
                                                                            id="description"
                                                                            value={formData.description}
                                                                            onChange={handleChange}
                                                                            rows="4"
                                                                            className="textarea-description"
                                                                            />
                                                                            ) : experience.description}
                                            </td>
                                            <td>
                                                {editExperienceId === experience.id ? (
                                                                        <select
                                                                            id="year"
                                                                             name="year"
                                                                            value={formData.year}
                                                                            onChange={handleChange}
                                                                            >
                                                                            {years.map((yearOption) => (
                                                                                                                        <option key={yearOption} value={yearOption}>
                                                                                                                            {yearOption}
                                                                                                                        </option>
                                                                                                                            ))}
                                                                        </select>
                                                                            ) : experience.year}
                                            </td>
                                            <td>
                                                {editExperienceId === experience.id ? (
                                                                        <select
                                                                            name="season"
                                                                            value={formData.season}
                                                                            onChange={handleChange}
                                                                            >
                                                                            <option value="SPRING">Spring</option>
                                                                            <option value="SUMMER">Summer</option>
                                                                            <option value="FALL">Fall</option>
                                                                            <option value="WINTER">Winter</option>
                                                                        </select>
                                                                            ) : experience.season}
                                            </td>
                                            <td>
                                                {editExperienceId === experience.id ? (
                                                                        <input
                                                                            type="text"
                                                                            name="employer"
                                                                            value={formData.employer}
                                                                            onChange={handleChange}
                                                                            />
                                                                            ) : experience.employer}
                                            </td>
                                            <td>
                                                {editExperienceId === experience.id ? (
                                                                        <>
                                                                        <button className="btn-save" onClick={handleSave}>Save</button>
                                                                        <button className="btn-cancel" onClick={handleCancel}>Cancel</button>
                                                                        <button className="btn-delete" onClick={() => handleDelete(experience.id)}>Delete</button>
                                                                        </>
                                                                            ) : (
                                                                        <button className="btn-edit" onClick={() => handleEdit(experience)}>Edit</button>
                                                                            )}
                                            </td>
                                        </tr>
                                                    ))}
                        </tbody>
                    </table>
            
                </div>
            </div>
            );
};

export default ExperienceManager;
