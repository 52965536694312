import React, { useState } from 'react';
import './CourseSelector.css';
import useCourseAPI from './hooks/useCourseAPI';

const CourseSelector = ({ courses, selectedCourse, setSelectedCourse,getUserCourses }) => {
    console.log(courses);
      const { addCourse } = useCourseAPI(getUserCourses);

    const handleSelectChange = (e) => {
        const courseId = e.target.value;
        const course = courses.find((c) => c.id.toString() === courseId);
        setSelectedCourse(course);
    };

    const handleOnClick = () => {
        if (selectedCourse) {
            addCourse(selectedCourse.id);
            setSelectedCourse(null);
        } else {
            console.log('No course selected');
        }
    };

    return (
        <div className="course-selector">
      <select onChange={handleSelectChange} value={selectedCourse ? selectedCourse.id : ''}> 
      <option value="">Select a course</option>
                {courses.map((course) => (
                    <option key={course.id} value={course.id}>
                        {course.courseName} - {course.courseNumber}
                    </option>
                ))}
            </select>
            <button className="select-button" onClick={handleOnClick}>
                Select
            </button>
        </div>
    );
};

export default CourseSelector;
