import React, { useState, useEffect } from 'react';
import useCourseAPI from './hooks/useCourseAPI';
import useForm from './hooks/useForm'; 
import CourseSelector from './../courseselector/CourseSelector';
import './CourseManager.css';




const daysOfWeek = ["M", "T", "W", "TH", "F"];
const availableRoles = ["TUTOR", "TEACHER"];



const CourseManager = () => {
    const { courses, updateCourse, userCourses, getUserCourses, deleteCourse } = useCourseAPI();
    const [editCourseId, setEditCourseId] = useState(null);
    const [selectedCourse, setSelectedCourse] = useState(null);


  const initialValues = {
    courseDays: [],
    roles: [],
    startTime: '',
    endTime: '',
    active: false,
    courseRoom: '',
  };
  
  const {
    formData,
    setFormData,
    handleChange,
    errors,
    setErrors,
    setHasChanges,
    hasChanges,
  } = useForm(initialValues);

  const handleCancel = () => {
    setEditCourseId(null);
    setFormData(initialValues);
  };

const handleDelete = async (courseId) => {
  try {
    await deleteCourse(courseId);
    console.log('Course deleted successfully');
    getUserCourses(); 
  } catch (error) {
    console.error('Failed to delete course:', error);
  }
};

const handleEdit = (course) => {
        setEditCourseId(course.id);
  setFormData({
    ...course,
    courseDays: Array.isArray(course.courseDays)
      ? course.courseDays
      : typeof course.courseDays === 'string'
      ? course.courseDays.split(',')
      : [],
    roles: Array.isArray(course.roles)
      ? course.roles
      : typeof course.roles === 'string'
      ? course.roles.split(',')
      : [],
    startTime: course.startTime || '',
    endTime: course.endTime || '',
    active: course.active || false,
    courseRoom: course.courseRoom || '',
  });
};


    const handleSave = async () => {
        const updatedCourse = {
            ...formData,
            courseTime: `${formData.startTime} - ${formData.endTime}`,
            courseDays: formData.courseDays,
            roles: formData.roles
        };

        try {
            console.log(updatedCourse);
            await updateCourse(editCourseId, updatedCourse);
            console.log('Course updated successfully');
            setEditCourseId(null);
            setFormData(initialValues);
        } catch (error) {
            console.error('Failed to update course:', error);
        }
    };

 const filteredCourses = courses.filter(
    (course) => !userCourses.some((userCourse) => userCourse.course.id === course.id)
  );

  return (
        <div className='course-management'>
            <CourseSelector 
                courses={filteredCourses} 
                selectedCourse={selectedCourse} 
                setSelectedCourse={setSelectedCourse}
                getUserCourses={getUserCourses}
            />
            <h3>Courses</h3>
            <table className="table">
                <thead>
                    <tr>
                        <th>Course Name</th>{/* */}
                        <th>Course Number</th>{/* */}
                        <th>Role</th>{/* */}
                        <th>Active</th>{/* */}
                        <th>Course Room</th>{/* */}
                        <th>Days</th>{/* */}
                        <th>Time</th>{/* */}
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {userCourses.map(course => (
                        <tr key={course.id}>
                            <td>{course.course.courseName}</td>{/* */}
                            <td>{course.course.courseNumber}</td>{/* */}
                            <td>
                                {editCourseId === course.id ? (
                                    availableRoles.map(role => (
                                        <label key={role}>
                                            <input
                                                type="checkbox"
                                                name="roles"
                                                value={role}
                                                checked={formData.roles.includes(role)}
                                                onChange={handleChange}
                                            /> {role}
                                        </label>
                                    ))
                                ) : course.roles ? course.roles.join(', ') : 'N/A'}
                            </td>{/* */}
                            <td>
                                {editCourseId === course.id ? (
                                    <input
                                        type="checkbox"
                                        name="active"
                                        checked={formData.active}
                                        onChange={handleChange}
                                    />
                                ) : course.active ? 'Yes' : 'No'}
                            </td>{/* */}
                            <td>
                                {editCourseId === course.id ? (
                                    <input
                                        type="text"
                                        name="courseRoom"
                                        value={formData.courseRoom || ''}
                                        onChange={handleChange}
                                    />
                                ) : course.courseRoom || 'N/A'}
                            </td>{/* */}
                            <td>
                                {editCourseId === course.id ? (
                                    daysOfWeek.map(day => (
                                        <label key={day}>
                                            <input
                                                type="checkbox"
                                                name="courseDays"
                                                value={day}
                                                checked={formData.courseDays.includes(day)}
                                                onChange={handleChange}
                                            /> {day}
                                        </label>
                                    ))
                                ) : course.courseDays ? course.courseDays.join(', ') : 'N/A'}
                            </td>{/* */}
                            <td>
                                {editCourseId === course.id ? (
                                    <>
                                        <input
                                            type="time"
                                            name="startTime"
                                            value={formData.startTime}
                                            onChange={handleChange}
                                        />
                                        to
                                        <input
                                            type="time"
                                            name="endTime"
                                            value={formData.endTime}
                                            onChange={handleChange}
                                        />
                                    </>
                                ) : `${course.startTime || 'N/A'} - ${course.endTime || 'N/A'}`}
                            </td>{/* */}
                            <td>
                                {editCourseId === course.id ? (
                                        <>
                                    <button className="btn-save" onClick={handleSave}>Save</button>
                                    <button className="btn-cancel" onClick={handleCancel}>Cancel</button>
                                    <button className="btn-delete" onClick={() => handleDelete(course.id)}>Delete</button>
                            </>
                                ) : (
                                    <button className="btn-edit" onClick={() => handleEdit(course)}>Edit</button>
                                )}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};


export default CourseManager;