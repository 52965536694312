import React, { useState, useRef, useEffect } from 'react';
import './JuliaSetGenerator.css';

function JuliaSetGenerator() {
    const [name, setName] = useState('');
    const [birthdate, setBirthdate] = useState('');
    const [colorScale, setColorScale] = useState(1); // Default scale factor
    const [maxIterations, setMaxIterations] = useState(200); // Default max iterations
    const [colorScheme, setColorScheme] = useState('grayscale'); // Default color scheme
    const [c, setC] = useState(null); // Store the complex parameter
    const [iterationData, setIterationData] = useState(null); // Store iteration counts
    const canvasRef = useRef(null);

    function handleNameChange(e) {
        setName(e.target.value);
        clearCanvas();
    }

    function handleBirthdateChange(e) {
        setBirthdate(e.target.value);
        clearCanvas();
    }

    function handleReset() {
        setName('');
        setBirthdate('');
        setColorScale(1); // Reset the scale
        setMaxIterations(200); // Reset iterations
        setColorScheme('grayscale'); // Reset color scheme
        setC(null); // Reset the complex parameter
        setIterationData(null); // Clear iteration data
        clearCanvas();
    }

    function generateFractal() {
        if (!name || !birthdate) {
            alert('Please enter both name and birthdate.');
            return;
        }
        const complexC = getCFromNameAndBirthdate(name, birthdate);
        setC(complexC); // Store the parameter
        const data = calculateJuliaSet(complexC); // Calculate iteration data
        setIterationData(data); // Store iteration data
        drawJuliaSet(data, colorScale, colorScheme); // Initial drawing
    }

    function clearCanvas() {
        const canvas = canvasRef.current;
        if (canvas) {
            const ctx = canvas.getContext('2d');
            ctx.clearRect(0, 0, canvas.width, canvas.height);
        }
    }

    function getCFromNameAndBirthdate(name, birthdate) {
        const nameString = name.trim().toLowerCase().replace(/\s+/g, '');
        let nameSum = 0;
        for (let i = 0; i < nameString.length; i++) {
            nameSum += nameString.charCodeAt(i);
        }
        const nameAvg = nameSum / nameString.length || 0;

        const birthdateString = birthdate.trim().replace(/\D/g, '');
        let birthdateSum = 0;
        for (let i = 0; i < birthdateString.length; i++) {
            birthdateSum += parseInt(birthdateString.charAt(i), 10);
        }
        const birthdateAvg = birthdateSum / birthdateString.length || 0;

        const realPart = mapValueToRange(nameAvg, 97, 122, -1.5, 1.5);
        const imagPart = mapValueToRange(birthdateAvg, 0, 9, -1.5, 1.5);

        return { real: realPart, imag: imagPart };
    }

    function mapValueToRange(value, inMin, inMax, outMin, outMax) {
        return outMin + ((value - inMin) * (outMax - outMin)) / (inMax - inMin);
    }

    function calculateJuliaSet(c) {
        const canvas = canvasRef.current;
        if (!canvas || !c) return null;
        const width = canvas.width;
        const height = canvas.height;

        const xmin = -1.5;
        const xmax = 1.5;
        const ymin = -1.5;
        const ymax = 1.5;

        const xScale = (xmax - xmin) / width;
        const yScale = (ymax - ymin) / height;

        const iterationData = new Array(width * height);

        for (let iy = 0; iy < height; iy++) {
            let y = ymin + iy * yScale;
            for (let ix = 0; ix < width; ix++) {
                let x = xmin + ix * xScale;
                let zx = x;
                let zy = y;
                let iteration = 0;

                while (zx * zx + zy * zy < 4 && iteration < maxIterations) {
                    const xTemp = zx * zx - zy * zy + c.real;
                    zy = 2 * zx * zy + c.imag;
                    zx = xTemp;
                    iteration++;
                }

                iterationData[iy * width + ix] = iteration; // Store iteration count
            }
        }

        return iterationData;
    }

    function drawJuliaSet(iterationData, scale, scheme) {
        const canvas = canvasRef.current;
        if (!canvas || !iterationData) return;
        const ctx = canvas.getContext('2d');
        const width = canvas.width;
        const height = canvas.height;

        const imageData = ctx.createImageData(width, height);
        const data = imageData.data;

        for (let i = 0; i < iterationData.length; i++) {
            const iteration = iterationData[i];
            const pixelIndex = i * 4;

            if (iteration === maxIterations) {
                data[pixelIndex] = 0;
                data[pixelIndex + 1] = 0;
                data[pixelIndex + 2] = 0;
                data[pixelIndex + 3] = 255;
            } else {
                const normalizedIteration = (iteration / maxIterations) * scale;

                let r, g, b;
                switch (scheme) {
                    case 'rainbow':
                        r = Math.floor(255 * Math.sin(0.1 * normalizedIteration));
                        g = Math.floor(255 * Math.sin(0.2 * normalizedIteration));
                        b = Math.floor(255 * Math.sin(0.3 * normalizedIteration));
                        break;
                    case 'blue-scale':
                        r = 0;
                        g = 0;
                        b = Math.floor(255 * normalizedIteration);
                        break;
                    case 'grayscale':
                    default:
                        r = g = b = Math.floor(255 * normalizedIteration);
                        break;
                }

                data[pixelIndex] = r; // Red
                data[pixelIndex + 1] = g; // Green
                data[pixelIndex + 2] = b; // Blue
                data[pixelIndex + 3] = 255; // Alpha
            }
        }

        ctx.putImageData(imageData, 0, 0);
    }

    useEffect(() => {
        if (iterationData) {
            drawJuliaSet(iterationData, colorScale, colorScheme);
        }
    }, [colorScale, colorScheme, maxIterations]);

    return (
        <div className="fractal-generator">
            <h1>Julia Set Generator</h1>
            <div className="fractal-input">
                <div className="form-group">
                    <label>Name:</label>
                    <input type="text" value={name} onChange={handleNameChange} />
                </div>
                <div className="form-group">
                    <label>Birthdate:</label>
                    <input type="date" value={birthdate} onChange={handleBirthdateChange} />
                </div>
                <div className="form-group">
                    <label>Max Iterations:</label>
                    <input
                        type="number"
                        min="50"
                        max="1000"
                        value={maxIterations}
                        onChange={(e) => setMaxIterations(parseInt(e.target.value))}
                    />
                </div>
                <div className="form-group">
                    <label>Color Scheme:</label>
                    <select value={colorScheme} onChange={(e) => setColorScheme(e.target.value)}>
                        <option value="grayscale">Grayscale</option>
                        <option value="rainbow">Rainbow</option>
                        <option value="blue-scale">Blue Scale</option>
                    </select>
                </div>
                <div className="form-group">
                    <label>Color Scale:</label>
                    <input
                        type="range"
                        min="0.1"
                        max="5"
                        step="0.1"
                        value={colorScale}
                        onChange={(e) => setColorScale(parseFloat(e.target.value))}
                    />
                    <span>{colorScale.toFixed(1)}</span>
                </div>
                <button className="btn-generate" onClick={generateFractal}>
                    Generate
                </button>
                <button className="btn-reset" onClick={handleReset}>
                    Reset
                </button>
            </div>
            <canvas
                className="fractal-view"
                ref={canvasRef}
                width={3840}
                height={2160}
            ></canvas>
        </div>
    );
}

export default JuliaSetGenerator;
