import React from "react";
import PropTypes from "prop-types";
import SocialIcon from './../socialicon/SocialIcon';
import socialPlatforms from './../../util/SocialPlatforms';
import './SocialMediaIcons.css'; 

const SocialMediaIcons = () => (
  <div className="social-media-icons">
    {socialPlatforms.map((platform, index) => (
      <SocialIcon key={index} {...platform} />
    ))}
  </div>
);

export default SocialMediaIcons;
