import React from 'react';
import PropTypes from "prop-types";
import './SocialIcon.css';

const SocialIcon = ({ href, fillColor = '#ffffff', svgPath, viewBox, ariaLabel }) => (
  <a
    href={href}
    aria-label={ariaLabel}
    target="_blank"
    rel="noopener noreferrer"
    className="social-icon"
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox={viewBox}
      className="social-icon-svg"
    >
      <path d={svgPath} />
    </svg>
  </a>
);


SocialIcon.propTypes = {
  href: PropTypes.string.isRequired,
  fillColor: PropTypes.string,
  svgPath: PropTypes.string.isRequired,
  viewBox: PropTypes.string.isRequired,
  ariaLabel: PropTypes.string.isRequired,
};

export default SocialIcon;
