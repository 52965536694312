// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.grid-item {
  border: 2px solid #ccc;
  border-radius: 10px;
  width: 100%; 
  height: auto; 
  max-width: 300px;
  max-height: 400px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: transform 0.3s;
  background-color: #f0f0f0; /* Light gray fallback in case of gaps */
}

.grid-item  {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center; /* Center the image */
  border-radius: 10px;
  transition: transform 0.3s;
}

img {
  width: 100%;
  height: 100%;
  object-position: center; /* Center the image */
  border-radius: 10px;
  transition: transform 0.3s;
}

.grid-item:hover  {
  transform: scale(1.1);
}
`, "",{"version":3,"sources":["webpack://./src/components/imagecard/ImageCard.css"],"names":[],"mappings":"AAAA;EACE,sBAAsB;EACtB,mBAAmB;EACnB,WAAW;EACX,YAAY;EACZ,gBAAgB;EAChB,iBAAiB;EACjB,gBAAgB;EAChB,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,0BAA0B;EAC1B,yBAAyB,EAAE,wCAAwC;AACrE;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,iBAAiB;EACjB,uBAAuB,EAAE,qBAAqB;EAC9C,mBAAmB;EACnB,0BAA0B;AAC5B;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,uBAAuB,EAAE,qBAAqB;EAC9C,mBAAmB;EACnB,0BAA0B;AAC5B;;AAEA;EACE,qBAAqB;AACvB","sourcesContent":[".grid-item {\n  border: 2px solid #ccc;\n  border-radius: 10px;\n  width: 100%; \n  height: auto; \n  max-width: 300px;\n  max-height: 400px;\n  overflow: hidden;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  transition: transform 0.3s;\n  background-color: #f0f0f0; /* Light gray fallback in case of gaps */\n}\n\n.grid-item  {\n  width: 100%;\n  height: 100%;\n  object-fit: cover;\n  object-position: center; /* Center the image */\n  border-radius: 10px;\n  transition: transform 0.3s;\n}\n\nimg {\n  width: 100%;\n  height: 100%;\n  object-position: center; /* Center the image */\n  border-radius: 10px;\n  transition: transform 0.3s;\n}\n\n.grid-item:hover  {\n  transform: scale(1.1);\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
