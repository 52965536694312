import React , {useState, useEffect} from 'react';
import axios from 'axios';







const useArtWorkAPI = () =>{
    const [artWorkList, setArtWorkList] = useState([]);
    
    
    
    
    useEffect(() => {
        getArtWork();
    }, []);
        
    const getArtWork = async () =>{
        try{
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/artwork`, {withCredentials: true});
         //   console.log(response.data.content);
            setArtWorkList(response.data.content);
        }catch(error){
            console.log("Could not retrieve ArtWork : ",error);            
        }

    
    };
    
        return {getArtWork, artWorkList};
    
};


export default useArtWorkAPI;