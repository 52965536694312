import React, { useState, useEffect } from 'react';
import axios from 'axios';





const useUserAPI = () => {
    const [user, setUser] = useState(null);
    useEffect(() => {

        getUserDetails();


    }, []);

    const getPresignedURL = async (fileName) => {
        const cleanedFileName = cleanFileName(fileName);
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/user/presign-url`, {
                params: {fileName: cleanedFileName},
                withCredentials: true
            });
            console.log(response.data);
            return response.data;
        } catch (error) {
            console.log("error retrieving url", error);
        }

    };

    const uploadToS3 = async (preSignedUrl, image) => {

        try {
            const response = await axios.put(preSignedUrl, image, {
                headers: {
                    'Content-Type': image.type
                }
            });

            console.log("Succesful upload");

        } catch (error) {
            console.log("error uploading to S3");
        }

    };

    const getUserDetails = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/user`, {withCredentials: true});
            if (response.status === 200) {
                console.log(response.data);
                setUser(response.data);
            }
        } catch (error) {
            console.log("Failed to retrieve User Details : ", error)
        }

    };
    
    
    const updateUserDetails = async (formData) => {
        console.log(formData);
        try {
            const response = await axios.put(`${process.env.REACT_APP_API_URL}/user`,formData, {withCredentials: true});
            if (response.status === 200) {
                console.log(response.data);
                setUser(response.data);
            }
        } catch (error) {
            console.log("Failed to retrieve User Details : ", error)
        }
        
    };






    return {getPresignedURL, uploadToS3, user, updateUserDetails};



};



export default useUserAPI;