const cleanFileName = (fileName) => {
    if (!fileName) return "";

    fileName = fileName.trim();

    const lastDotIndex = fileName.lastIndexOf('.');
    if (lastDotIndex === -1) return fileName; 

    let namePart = fileName.slice(0, lastDotIndex);
    let extension = fileName.slice(lastDotIndex + 1);

    namePart = namePart
        .replace(/\s+/g, '_')           
        .replace(/[^a-zA-Z0-9-_]/g, ''); 

    extension = extension.toLowerCase();   

    return `${namePart}.${extension}`;
};

export default cleanFileName;