import React, { useState } from 'react';
import axios from 'axios';
import useLoginAPI from './hooks/useLoginAPI';


import './Login.css';

const Login = () => {
    const [userName, setUserName] = useState('');
    const [password, setPassword] = useState("");
    const { login } = useLoginAPI();
    
    
    
    
    

    const handleChange = (event) => {
        const { name, value } = event.target;

        if (name === 'username') {
            setUserName(value);
        } else {
            setPassword(value);
        }
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        console.log("Login Attempt with:", userName, password);
        login(userName, password);
        
    };

    return (
        <div className="login-container">
            <h1>Login</h1>
            <form onSubmit={handleSubmit}>
                <div className="form-group">
                    <label htmlFor="username">Username:</label>
                    <input
                        type="text"
                        id="username"
                        name="username"
                        value={userName}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="password">Password:</label>
                    <input
                        type="password"
                        id="password"
                        name="password"
                        value={password}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div>
                    <button type="submit">Login</button>
                </div>
            </form>
        </div>
    );
};

export default Login;
