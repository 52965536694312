import React from 'react';
import axios from 'axios';
import cleanFileName from './../../../util/FileNameCleaner';



const useArtWorkAPI = () => {

    const getPresignedURL = async (fileName) => {
        const cleanedFileName = cleanFileName(fileName); 
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/artwork/presign-url`, {
                params: {fileName : cleanedFileName},
                withCredentials: true
            });
            console.log(response.data);
            return response.data; 
        } catch (error) {
            console.log("error retrieving url", error);
        }

    };
    
    const uploadToS3 = async (preSignedUrl, image, onProgress) => {
        try {
            const response = await axios.put(preSignedUrl, image, {
                headers: { 'Content-Type': image.type },
                onUploadProgress: (progressEvent) => {
                    const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                    onProgress(percentCompleted); // Update the progress
                }
            });
            console.log("Successful upload");
        } catch (error) {
            console.log("error uploading to S3", error);
        }
    };
    
    const addArtWork = async (artWork) => {
      try{
          const response = await axios.post(`${process.env.REACT_APP_API_URL}/artwork`, artWork, {withCredentials:true} );
          console.log(response.data);
      }catch(error){
          console.log("Error adding artwork", error);
      }  
    };
    
    
    return {getPresignedURL, uploadToS3, addArtWork};

};


export default useArtWorkAPI;