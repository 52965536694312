import React, {useState, useEffect} from 'react';
import axios from 'axios';


const useExperienceAPI = () =>{
    
    const [experienceList, setExperienceList] = useState([]);
  
  useEffect(() => {
      getExperience();
  }, []);
  
  
  
  const addExperience = async (experience) => {
      try{
          const response = await axios.post(`${process.env.REACT_APP_API_URL}/experience`, experience,{ withCredentials : true } );
        //  console.log("Successfully added Experience : ",response.data);
                getExperience();

      }catch(error){
          console.log("Failed to add experience : ",error);
          
      }
      
  } ;
  
  const getExperience = async () => {
    try{
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/experience`, {withCredentials:true});
      //  console.log(response.data);
        setExperienceList(response.data);
    }catch(error){
        console.log("Failed to retrieve experience listing : ", error);
        
    }  
      
  };
  
  
  const deleteExperience= async (id) => {
      try{
          const response = axios.delete(`${process.env.REACT_APP_API_URL}/experience/${id}`)
        setExperienceList(prevList => prevList.filter(experience => experience.id !== id));
    }catch(error){
          console.log(error);
          
      }
      
  };
  
  const updateExperience = async (id, experience) => {
      try{
          const response = await axios.put(`${process.env.REACT_APP_API_URL}/experience/${id}`, experience, {withCredentials : true});
          getExperience();
      }catch(error){
          console.log(error);
      }
      
  };
  
  
  
  return {addExperience, getExperience, deleteExperience, updateExperience, experienceList};
    
    
};

export default useExperienceAPI;