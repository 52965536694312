import React, {useEffect, useState} from 'react';
import axios from 'axios';


const useUserAPI = () => {
    const [courses, setCourses] = useState([]);
    const [user, setUser] = useState({});




    useEffect(() => {
        getUserDetails();
    }, []);
    
    
    const getPresignedURL = async (fileName) => {
        const cleanedFileName = cleanFileName(fileName); 
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/user/presign-url`, {
                params: {fileName : cleanedFileName},
                withCredentials: true
            });
           // console.log(response.data);
            return response.data; 
        } catch (error) {
            console.log("error retrieving url", error);
        }

    };
    
    const uploadToS3 = async (preSignedUrl, image) => {
            
            try{
                const response = await axios.put(preSignedUrl, image, {
                    headers : {
                        'Content-Type' : image.type
                    }
                });
                
                console.log("Succesful upload");
                
            }catch(error){
                console.log("error uploading to S3");
            }
        
    };
    
const getUserDetails = async () => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/user`);
    setCourses(response.data.userCourses);
    //console.log(response.data);
    setUser(response.data);
  } catch (error) {
    console.error("There was an error", error);
  }
};


    return {getPresignedURL, uploadToS3, user, courses};

};


export default useUserAPI;