// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.carousel-container {
  width: 80%;

  overflow: hidden;
  background-color: #fff;
  border: 1px solid #ccc;
}

.carousel {
  display: flex;
  width: 100%;
  animation: scroll 20s linear infinite;
}

.carousel img {
  width: 200px;
  height: fit-content;
  margin-right: 20px;
  border-radius: 10px;
  transition: transform 0.3s;
}

carousel a {
  display: block;
  max-height: 40%
}

.carousel img:hover {
  transform: scale(1.1);
}

@keyframes scroll {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/carouselcontainer/CarouselContainer.css"],"names":[],"mappings":"AAAA;EACE,UAAU;;EAEV,gBAAgB;EAChB,sBAAsB;EACtB,sBAAsB;AACxB;;AAEA;EACE,aAAa;EACb,WAAW;EACX,qCAAqC;AACvC;;AAEA;EACE,YAAY;EACZ,mBAAmB;EACnB,kBAAkB;EAClB,mBAAmB;EACnB,0BAA0B;AAC5B;;AAEA;EACE,cAAc;EACd;AACF;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE;IACE,2BAA2B;EAC7B;EACA;IACE,4BAA4B;EAC9B;AACF","sourcesContent":[".carousel-container {\n  width: 80%;\n\n  overflow: hidden;\n  background-color: #fff;\n  border: 1px solid #ccc;\n}\n\n.carousel {\n  display: flex;\n  width: 100%;\n  animation: scroll 20s linear infinite;\n}\n\n.carousel img {\n  width: 200px;\n  height: fit-content;\n  margin-right: 20px;\n  border-radius: 10px;\n  transition: transform 0.3s;\n}\n\ncarousel a {\n  display: block;\n  max-height: 40%\n}\n\n.carousel img:hover {\n  transform: scale(1.1);\n}\n\n@keyframes scroll {\n  0% {\n    transform: translateX(100%);\n  }\n  100% {\n    transform: translateX(-100%);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
