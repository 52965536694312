// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../public_html/images/sphere.png", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../../../public_html/images/cylinder.png", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("../../../public_html/images/torus.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.contact-content {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    padding: 2rem;
    background-image: 
        url(${___CSS_LOADER_URL_REPLACEMENT_0___}),
        url(${___CSS_LOADER_URL_REPLACEMENT_1___}),
        url(${___CSS_LOADER_URL_REPLACEMENT_2___}); 
    background-repeat: no-repeat;
    background-size: 20%, 20%, 40%;
    background-position: bottom right, bottom left, center right;
    background-color: #f9f9f9;
    font-family: Arial, sans-serif;
    color: #333;
}

.contact-content h1 {
    font-size: 2.5rem;
    margin-bottom: 1rem;
}

.contact-content p {
    font-size: 1.1rem;
    line-height: 1.6;
    margin-bottom: 1rem;
}

.contact-content a {
    color: #007acc;
    text-decoration: none;
    font-weight: bold;
}

.contact-content a:hover {
    text-decoration: underline;
}

.contact-options {
    margin-top: 2rem;
}

.contact-options h2 {
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
}

.contact-options ul {
    list-style-type: none;
    padding-left: 0;
}

.contact-options li {
    font-size: 1.1rem;
    margin-bottom: 0.5rem;
}

.bold {
    font-weight: bold;
}
`, "",{"version":3,"sources":["webpack://./src/components/contact/Contact.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,iBAAiB;IACjB,aAAa;IACb;;;+CAGgD;IAChD,4BAA4B;IAC5B,8BAA8B;IAC9B,4DAA4D;IAC5D,yBAAyB;IACzB,8BAA8B;IAC9B,WAAW;AACf;;AAEA;IACI,iBAAiB;IACjB,mBAAmB;AACvB;;AAEA;IACI,iBAAiB;IACjB,gBAAgB;IAChB,mBAAmB;AACvB;;AAEA;IACI,cAAc;IACd,qBAAqB;IACrB,iBAAiB;AACrB;;AAEA;IACI,0BAA0B;AAC9B;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,iBAAiB;IACjB,qBAAqB;AACzB;;AAEA;IACI,qBAAqB;IACrB,eAAe;AACnB;;AAEA;IACI,iBAAiB;IACjB,qBAAqB;AACzB;;AAEA;IACI,iBAAiB;AACrB","sourcesContent":[".contact-content {\n    display: flex;\n    flex-direction: column;\n    min-height: 100vh;\n    padding: 2rem;\n    background-image: \n        url('../../../public_html/images/sphere.png'),\n        url('../../../public_html/images/cylinder.png'),\n        url('../../../public_html/images/torus.png'); \n    background-repeat: no-repeat;\n    background-size: 20%, 20%, 40%;\n    background-position: bottom right, bottom left, center right;\n    background-color: #f9f9f9;\n    font-family: Arial, sans-serif;\n    color: #333;\n}\n\n.contact-content h1 {\n    font-size: 2.5rem;\n    margin-bottom: 1rem;\n}\n\n.contact-content p {\n    font-size: 1.1rem;\n    line-height: 1.6;\n    margin-bottom: 1rem;\n}\n\n.contact-content a {\n    color: #007acc;\n    text-decoration: none;\n    font-weight: bold;\n}\n\n.contact-content a:hover {\n    text-decoration: underline;\n}\n\n.contact-options {\n    margin-top: 2rem;\n}\n\n.contact-options h2 {\n    font-size: 1.5rem;\n    margin-bottom: 0.5rem;\n}\n\n.contact-options ul {\n    list-style-type: none;\n    padding-left: 0;\n}\n\n.contact-options li {\n    font-size: 1.1rem;\n    margin-bottom: 0.5rem;\n}\n\n.bold {\n    font-weight: bold;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
