import React, { useState } from 'react';
import ExperienceManager from './../experiencemanager/ExperienceManager';
import ResearchManager from './../researchmanager/ResearchManager';
import AccountManager from './../accountmanager/AccountManager';
import ArtWorkManager from './../artworkmanager/ArtWorkManager';
import CourseManager from './../coursemanager/CourseManager';
import './Settings.css';
        /** rename to settings */
const Settings = () => {
    const [selectedComponent, setSelectedComponent] = useState('AccountManager');

    const renderComponent = () => {
        switch (selectedComponent) {
            case 'AccountManager':
                return <AccountManager />;
            case 'ResearchManager':
                return <ResearchManager />;
            case 'ExperienceManager':
                return <ExperienceManager />;
            case 'CourseManager':
                return <CourseManager />;
            case 'ArtWorkManager':
                return <ArtWorkManager />;
            default:
                return <AccountManager />;
        }
    };

    return (
        <div className="account-container">
            <div className="sidebar">
                <button onClick={() => setSelectedComponent('AccountManager')}>Account Manager</button>
                <button onClick={() => setSelectedComponent('ResearchManager')}>Research Manager</button>
                <button onClick={() => setSelectedComponent('ExperienceManager')}>Experience Manager</button>
                <button onClick={() => setSelectedComponent('CourseManager')}>Course Manager</button>
                <button onClick={() => setSelectedComponent('ArtWorkManager')}>ArtWork Manager</button>
            </div>
            <div className="content">
                {renderComponent()}
            </div>
        </div>
    );
};

export default Settings;
