import React, { useState } from 'react';

const ExperienceCreator = ({ getExperience, addExperience }) => {
  const currentYear = new Date().getFullYear();
  const years = Array.from({ length: 50 }, (_, i) => currentYear - i); 

  const [formData, setFormData] = useState({
    title: '',
    description: '',
    year: currentYear,
    season: 'SPRING',
    employer: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const experience = { ...formData };
    console.log(experience);
    addExperience(experience);
    getExperience();

    setFormData({
      title: '',
      description: '',
      year: currentYear,
      season: 'SPRING',
      employer: '',
    });
  };

  return (
    <div className="experience-form">
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="title">Title:</label>
          <input
            id="title"
            name="title" 
            type="text"
            value={formData.title}
            onChange={handleChange}
          />
        </div>
        <div className="form-group">
          <label htmlFor="employer">Employer:</label>
          <input
            id="employer"
            name="employer"
            type="text"
            value={formData.employer}
            onChange={handleChange}
          />
        </div>
        <div className="form-group">
          <label htmlFor="year">Year:</label>
          <select
            id="year"
            name="year"
            value={formData.year}
            onChange={handleChange}
          >
            {years.map((yearOption) => (
              <option key={yearOption} value={yearOption}>
                {yearOption}
              </option>
            ))}
          </select>
        </div>

        <div className="form-group">
          <label htmlFor="season">Season:</label>
          <select
            id="season"
            name="season" 
            value={formData.season}
            onChange={handleChange}
          >
            <option value="SPRING">Spring</option>
            <option value="SUMMER">Summer</option>
            <option value="FALL">Fall</option>
            <option value="WINTER">Winter</option>
          </select>
        </div>
        <div className="form-group">
          <label htmlFor="description">Description:</label>
          <textarea
            id="description"
            name="description" 
            value={formData.description}
            onChange={handleChange}
            rows="4"
            className="textarea-description"
            required
          />
        </div>

        <button type="submit" className="submit-button">
          Add Experience
        </button>
      </form>
    </div>
  );
};

export default ExperienceCreator;
